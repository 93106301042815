import React, { useState, useRef, useEffect } from 'react';
import './App.css';
import soundFile from './audio.mp3';
import backgroundImage from './Harris.png';

const App = () => {
  const [showImage, setShowImage] = useState(false);
  const audioRef = useRef(null);

  const playSound = () => {
    audioRef.current.play();
    setShowImage(true);
    // Change favicon to coconut
    window.changeFavicon('🥥');
  };

  useEffect(() => {
    const handleAudioEnd = () => {
      setShowImage(false);
      // Change favicon back to old man
      window.changeFavicon('👴');
    };

    const audio = audioRef.current;
    audio.addEventListener('ended', handleAudioEnd);

    return () => {
      audio.removeEventListener('ended', handleAudioEnd);
    };
  }, []);

  return (
    <div className="App">
      <div 
        className={`background-image ${showImage ? 'show' : ''}`} 
        style={{backgroundImage: `url(${backgroundImage})`}} 
      />
      <header className="App-header">
        <h1>Has Joe Biden Dropped Out?</h1>
        <p className="status" onClick={playSound}>YES!</p>
        <audio ref={audioRef}>
          <source src={soundFile} type="audio/mpeg" />
          Your browser does not support the audio element.
        </audio>
      </header>
    </div>
  );
};

export default App;